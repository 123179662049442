/* eslint-disable import/unambiguous */
(function IIFE() {
    /////////////////////////////

    const isCustomDomain = window.location.pathname.indexOf('/a/') === -1;
    const locationPath = window.location.pathname;
    const splittedPath = locationPath.split('/') || (isCustomDomain ? ['', '', ''] : ['', 'a', '', '']);
    const isCommunityContext = locationPath.indexOf('/ls/community/') !== -1 || locationPath.indexOf('/ls/group/') !== -1;

    /**
     * Compute the query string part of the URL as an object.
     *
     * @return {Object} The query string parameters.
     */
    function computeSearchParams() {
        const search = {};
        const searchParams = (window.location.search || '').replace('?', '').split('&') || [];

        searchParams.forEach(function forEachSearchParameters(searchParam) {
            const split = searchParam.split('=');

            if (!split || split.length === 0) {
                return;
            } else if (split.length === 1) {
                split[1] = undefined;
            }

            search[split[0]] = split[1];
        });

        return search;
    }

    /**
     * Retrieve customer slug from url.
     *
     * @return {string} The current customer slug.
     */
    function getCustomerSlug() {
        /*
         * Fallback to computing the slug with the URL.
         * There is two cases:
         *  - the URL contains a '/a/' part, which mean that the customer slug is the next part enclosed by '/';
         *  - the URL doesn't contains a '/a/' part (it usually is the case for custom domains), in which case the
         *    "slug" (in fact, the part that can be used to identify the customer) is the domain of the URL;
         */
        return isCustomDomain ? window.location.host : splittedPath[2];
    }

    /**
     * Retrieve instance slug from url.
     *
     * @return {string} The current instance slug.
     */
    function getCurrentInstanceSlug() {
        /*
         * Fallback to computing the slug with the URL.
         * There is two cases:
         *  - the URL contains a '/a/' part, which mean that the customer slug is the next part enclosed by '/';
         *  - the URL doesn't contains a '/a/' part (it usually is the case for custom domains), in which case the
         *    "slug" (in fact, the part that can be used to identify the customer) is the domain of the URL;
         */
        const currentInstanceSlug = isCustomDomain ? splittedPath[1] : splittedPath[3];

        if (currentInstanceSlug !== 'gsite') {
            return currentInstanceSlug;
        }

        const searchParams = computeSearchParams();

        if (!searchParams.parent) {
            return currentInstanceSlug;
        }

        // The parent path is "https://sites.google.com/a/customerSlug/instanceSlug/?s=contentSlug".
        let splittedParentPath = decodeURIComponent(searchParams.parent).split('/') || [];
        if (splittedParentPath.length < 6) {
            return currentInstanceSlug;
        }

        splittedParentPath = splittedParentPath[5];
        // Remove eventual param .
        splittedParentPath = (splittedParentPath.split('?') || [splittedParentPath])[0];

        // Remove eventual anchor.
        return (splittedParentPath.split('#') || [splittedParentPath])[0];
    }

    /**
     * Get the slug of a content.
     *
     * @param  {string} currentInstanceSlug The current instance slug.
     * @return {string} The content slug.
     */
    function getContentSlug(currentInstanceSlug) {
        let communityPrefixIndex;

        // For a community, the content slug is prefixed by an `ls/community` or `ls/group`.
        if (isCommunityContext) {
            const instanceIndex = splittedPath.indexOf(currentInstanceSlug);

            if (splittedPath[instanceIndex + 1] === 'ls') {
                communityPrefixIndex = instanceIndex + 2;
            } else {
                const communityStrIndex = splittedPath.indexOf('community');
                communityPrefixIndex = communityStrIndex !== -1 ? communityStrIndex : splittedPath.indexOf('group');
            }
        }

        return isCommunityContext ? splittedPath[communityPrefixIndex + 1] : splittedPath[splittedPath.length - 1];
    }

    /**
     * Retrieve current content slug from url.
     *
     * @return {string} The current current content slug.
     */
    function getCurrentContentSlug() {
        const currentInstanceSlug = getCurrentInstanceSlug();

        const searchParams = computeSearchParams();

        if (!searchParams.parent) {
            return getContentSlug(currentInstanceSlug);
        }

        // The parent path is "https://sites.google.com/a/customer/instanceSlug/?s=contentSlug".
        let splittedParentPath = decodeURIComponent(searchParams.parent).split('?') || [];
        if (splittedParentPath.length < 2) {
            return getContentSlug(currentInstanceSlug);
        }

        // Only keep the search query parameters of the `parent` parameter.
        splittedParentPath = splittedParentPath[1];
        const splittedParams = splittedParentPath.split('&');
        if (splittedParams.length === 0) {
            return getContentSlug(currentInstanceSlug);
        }

        // Search for the search query parameter `s` which contains the slug of the content we want to load.
        for (let i = 0; i < splittedParams.length; i++) {
            const param = splittedParams[i].split('=');
            if (param.length < 2) {
                continue;
            }

            if (param[0] !== 's') {
                continue;
            }

            return param[1];
        }

        return getContentSlug(currentInstanceSlug);
    }

    /**
     * Checks whether a content given by a route has to be feteched asynchronously once the app is loaded.
     *
     * @param  {string}  instance The current instance slug.
     * @return {boolean} Whether it has to be fetched asynchronously.
     */
    function needToBeFetchedAsynchronously(instance) {
        // eslint-disable-next-line no-undef
        const filteredRoutes = ROUTES_TO_FETCH_ASYNCHRONOUSLY.filter(function filterRoutes(route) {
            return locationPath.indexOf(instance + route) !== -1;
        });

        return filteredRoutes.length > 0;
    }

    const customerSlug = getCustomerSlug();
    const instanceSlug = getCurrentInstanceSlug();
    const contentSlug = getCurrentContentSlug();

    const customerKey = isCustomDomain ? 'customerHost' : 'customerSlug';
    const contentKey = needToBeFetchedAsynchronously(instanceSlug, contentSlug) ? 'contentId' : 'slug';

    const params = {
        instanceSlug,
        loadInstance: !(
            (instanceSlug === 'login' && contentSlug === 'account') ||
            (instanceSlug === 'admin' && contentSlug === 'customer')
        ),
    };

    params[customerKey] = customerSlug;
    params[contentKey] = contentSlug;
    if (isCommunityContext) {
        // Required by genesis because content & communities can share a slug and genesis cannot know which one we want.
        params['resourceType'] = 'community';
    }

    const queryParams = Object.keys(params)
        .map(function mapQueryParams(key) {
            return `${key}=${params[key]}`;
        })
        .join('&');

    const request = new XMLHttpRequest();
    const url = `${window.HAUSSMANN_CELL}/service/init?${queryParams}`
    window.INIT_URL = url;

    request.open('GET', url);
    request.setRequestHeader('Content-type', 'application/json');
    request.setRequestHeader('Accept', 'application/json');
    request.setRequestHeader('Lumapps-Organization-Id', window.CUSTOMER_ID);
    request.setRequestHeader('Lumapps-Web-Client-Version', window.BUILD_FRONTEND_VERSION);

    // One-time content analytics turn off (used to avoid duplicate view when routing v1/v2 content).
    if (window.sessionStorage && window.sessionStorage.getItem('page-load-content-analytics-disable')) {
        request.setRequestHeader('x-lumapps-analytics', 'off');
        window.sessionStorage.removeItem('page-load-content-analytics-disable');
    } else {
        request.setRequestHeader('x-lumapps-analytics', 'on');
    }

    if (window.USER_ACCESS_TOKEN) {
        request.setRequestHeader('Authorization', `Bearer ${window.USER_ACCESS_TOKEN}`);
    }

    /* Set a LUMAPPS-PUBLIC-UNIQUE-ID header for non-connected users
     * It will be used for analytics purpose
     */
    if (!window.USER_ACCESS_TOKEN) {
        request.setRequestHeader('LUMAPPS-PUBLIC-UNIQUE-ID', window.localStorage.getItem('LUMAPPS-PUBLIC-UNIQUE-ID'));
    }

    request.send({});
    window.initRequest = request
    /* eslint-enable angular/window-service, angular/json-functions, lumapps/file-format, lumapps/angular-isdefined */
})();
